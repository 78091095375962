.component {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (--viewport-md) {
    padding: var(--size-48);
  }

  & > .contentLayout {
    z-index: 1;
    width: 100%;
    height: 100%;
    align-self: center;

    @media (--viewport-md) {
      margin-bottom: var(--size-40);
    }
  }

  & > .poweredByLayout {
    margin-bottom: var(--size-72);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }
}


.componentPoweredBy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--size-4);
  color: var(--color-gray-500);
  line-height: 1;
  opacity: 0.5;
  font-style: italic;

  @media (--viewport-md) {
    flex-direction: row;
    justify-content: center;
  }
}

.rabobank {
  font-weight: var(--font-weight-600);
}
