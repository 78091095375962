.component {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .playerContainer {
    z-index: 0;
    width: 100%;
  }

  & > .description {
    margin-top: var(--size-12);
  }
}

.playerContainer {
  z-index: 0;
  position: relative;
  padding-top: 120%;
  overflow: hidden;

  @media (--viewport-md) {
    padding-top: 56.25%;
  }

  & > .player {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .playButton {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  & > .posterLayout {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.playButton {
  --opacity: 1;
  --scale: 1;
  --button-background: var(--color-black--10);

  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: var(--size-48);

  &:hover {
    --scale: 1.05;
    --button-background: var(--color-black--40);
  }

  &.playing {
    --opacity: 0;

    pointer-events: none;
  }

  & > .playButtonLayout {
    height: var(--size-40);
  }
}

.componentPlayButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--size-12);
  padding: var(--size-12) var(--size-20);
  border-radius: var(--radius-20);
  border: 1px solid var(--color-white--20);
  backdrop-filter: blur(10px);
  box-shadow: 0 7px 20px rgba(0, 91, 130, 0.12);
  color: var(--color-white);
  background-color: var(--button-background);
  opacity: var(--opacity);
  transform: scale(var(--scale));
  transition: var(--duration-400) ease;
  overflow: hidden;

  & > .iconLayout {
    width: var(--size-14);
    height: var(--size-14);
  }
}

.buttonLabel {
  font-family: var(--font-family-base);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-text);
  white-space: nowrap;
}

.description {
  font-size: var(--size-14);
  line-height: var(--line-height-text);
}
