.component,
.componentButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-50);
  backdrop-filter: blur(var(--blur-26));
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-heading);
}

.componentButton {
  z-index: 0;
  position: relative;
  overflow: hidden;
  pointer-events: auto;

  &::before {
    content: '';
    opacity: 0;
    z-index: -1;
    position: absolute;
    inset: 0;
    background-color: var(--color-white);

    @media (--viewport-md) {
      content: '';
      opacity: 0;
      z-index: -1;
      position: absolute;
      inset: 0;
      transform: translateX(0%);
      transform-origin: left;
      background-color: var(--color-white);
      transition: transform var(--duration-600) ease;
    }
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 0.4;

      @media (--viewport-md) {
        opacity: 0;
        animation: slideIn var(--duration-2000) ease;
      }
    }
  }
}

.componentWrapper {
  display: flex;
  flex-wrap: wrap;
  font-family: var(--font-family-base);
  gap: var(--size-4);
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scaleX(0%);
  }

  20% {
    opacity: 0.4;
    transform: scaleX(100%);
  }

  100% {
    opacity: 0;
  }
}
