.component {
  & > .contentLayout {
    height: 100%;
  }
}

.componentContent {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
  grid-template-areas: "image" "header";
  padding: var(--size-24);
  gap: var(--size-32);

  @media (--viewport-md) {
    grid-template-areas: "image header";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: var(--size-48);
    place-items: center;
    padding-right: var(--size-100);
  }

  & > .imageBoxLayout {
    position: static;
    grid-area: image;
    width: 100%;

    @media (--viewport-md) {
      max-width: 400px;
    }
  }

  & > .kunstcollectieLink {
    position: static;
    grid-area: header;
  }
}

.imageBox {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-areas: "content";

  & > * {
    grid-area: content;
  }

  & > .imageLayout {
    width: 100%;
  }

  & > .plusIconContainer {
    z-index: 1;
    position: static;
    place-self: end;
    margin-bottom: calc(-1 * var(--size-18));
    margin-right: var(--size-32);
  }
}

.plusIconContainer {
  position: relative;

  & > .plusIconLayout {
    position: static;
    width: var(--size-40);
    height: var(--size-40);
  }
}

.kunstcollectieLink {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.imageContainerMobile {
  @media (--viewport-md) {
    display: none;
  }
}

.imageContainerDesktop {
  @media not (--viewport-md) {
    display: none;
  }
}
