.componentContentBase {
  overflow: hidden;
  padding: var(--size-24) var(--size-30) var(--size-20);
  background-color: var(--color-white);
  border-radius: var(--size-24);
}

.componentContentCanonical,
.articlesContainer {
  display: flex;
  flex-direction: column;

  @media not (--viewport-lg) {
    padding-bottom: var(--size-80)
  }

  & > .buttonLayout {
    align-self: center;
    margin-top: var(--size-16);
    width: max-content;
  }
}

.differentRegionsHeading {
  padding: var(--size-48) 0 var(--size-2);
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-400);
  font-style: italic;
}

.componentHeading {
  padding-right: var(--size-64);
  font-family: var(--font-family-base);
  font-size: var(--font-size-34);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-heading-group-title);
  color: var(--color-gray-900);
  overflow: visible;
}

.componentHeadingGreen {
  color: var(--color-green-300);
  font-style: italic;
}

.componentHeadingCream {
  color: var(--color-cream-500);
  font-style: italic;
}

.componentHeadingCyan {
  color: var(--color-gray-500);
  font-style: italic;
}
