
.component {
  display: flex;
  flex-direction: column;
  background-color: var(--color-cyan-300);
  padding: var(--size-24) var(--size-35);

  & > .body {
    margin-bottom: var(--size-14);
  }

  & > .list {
    margin-bottom: var(--size-30);
  }
}

.body {
  font-size: var(--font-size-14);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.componentListItem {
  font-size: var(--font-size-14);
  background-color: var(--color-white);
  box-shadow: var(--shadow-card);
  border-radius: var(--radius-8);
  transform: scale(1);
  transition:
    transform var(--duration-100) ease,
    outline var(--duration-100) ease;

  &:hover,
  &:focus {
    transform: scale(1.01);
  }
}

.listItemLabel {
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .fauxcheck {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.componentForm {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .buttonLayout {
    width: max-content;
    align-self: center;
  }
}

.componentResults {
  display: flex;
  flex-direction: column;
  gap: var(--size-12);
}

.result {
  display: flex;
  flex-direction: column;
  gap: var(--size-6);
  padding: var(--size-10) var(--size-16);
  background-color: var(--color-white);
  border-radius: var(--radius-8);
  box-shadow: var(--shadow-card);

  & > .resultLabel {
    margin: 0;
  }
}

.resultLabel {
  cursor: default;
  font-size: var(--font-size-16);
  padding: 0;
}

.resultRow {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  font-size: var(--font-size-16);

  & > .progressLayout {
    height: var(--size-6);
    width: 100%;
    flex: 1;
  }
}

.componentSkeleton {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--size-12);

  & > .bone {
    height: 45px;
    width: 100%;
  }
}

.bone {
  display: grid;
  grid-template-areas:
    'checkbox ...'
    'checkbox question'
    'checkbox ...';
  grid-template-columns: var(--size-24) 1fr;
  grid-template-rows: var(--size-6) var(--size-12) var(--size-6);
  padding: var(--size-10);
  background-color: var(--color-white);
  border-radius: var(--radius-8);
  box-shadow: var(--shadow-card);
  column-gap: var(--size-12);

  &::after {
    content: '';
    grid-area: checkbox;
    background-color: var(--color-green-200);
    border-radius: var(--radius-4);
  }

  &::before {
    content: '';
    grid-area: question;
    background-color: var(--color-gray-100);
    border-radius: var(--radius-4);
  }
}

.componentProgress {
  appearance: none;
  background: transparent;
  border-radius: var(--radius-8);
  overflow: hidden;

  &::-webkit-progress-bar {
    border-radius: var(--radius-8);
    background-color: transparent;
    overflow: hidden;
    height: inherit;
  }

  &::-webkit-progress-value {
    border-radius: var(--radius-8);
    background: var(--color-green-300);
    height: inherit;
  }
}
