.component {
  position: relative;
  background-color: var(--color-black--20);
  backdrop-filter: blur(var(--blur-10));

  & > .fillableBarLayout {
    height: 100%;
  }

  & > .barContentLayout {
    position: absolute;
    width: 100%;
    height: var(--size-35);
    top: 0;
    left: 0;
  }
}

.componentFillableBar {
  opacity: 0.5;
  background-color: var(--color-white);
}

.componentBarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--size-14);
  color: var(--color-gray-900);

  & > .checkMarkLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.barContentLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-14);
}

.backLink {
  & > .iconLayout {
    width: var(--size-14);
    height: var(--size-14);
  }
}

.readTimeText {
  font-size: var(--font-size-14);
}

.componentCheckMarkIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px var(--color-white) dashed;
  color: var(--color-white--20);
  will-change: color;
  transition: color var(--duration-300) ease;

  &.checkMarkReachedEnd {
    color: var(--color-gray-900);
  }

  & > .checkMarkIconLayout {
    width: var(--size-12);
    height: var(--size-12);

  }
}
