.overlay {
  padding: var(--size-14);
  background-color: var(--color-black--60);
  backdrop-filter: blur(var(--blur-2));
  overflow: hidden !important;

  @media (--viewport-md) {
    padding: 2.5vh 5vw;
  }

  &.noPadding {
    padding: 0;
  }

  &.black {
    background-color: var(--color-black);
  }
}

.modalContainer {
  display: grid;
  grid-template-areas: 'content';
  pointer-events: auto;
  overflow-y: auto;

  @media (--viewport-md) {
    border-radius: var(--size-24);
  }

  & > * {
    grid-area: content;
  }

  & > .closeButtonLayout {
    place-self: end center;
    margin: var(--size-24);
    height: var(--size-48);
    width: var(--size-48);
    bottom: var(--size-24);

    @media (--viewport-lg) {
      place-self: start end;
      bottom: unset;
      top: var(--size-24);
    }
  }
}

._rootModal {
  pointer-events: none;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  & > * {
    height: 100%;
    width: 100%;
    margin: 0 auto;

    @media (--viewport-md) {
      height: 95%;
    }
  }

  & > .containerMd {
    @media (--viewport-md) {
      width: 65%;
      max-width: 600px;
    }
  }

  & > .containerXl {
    @media (--viewport-md) {
      width: 85%;
    }
  }
}

.componentCloseButton {
  position: sticky;
  display: grid;
  place-items: center center;
  color: var(--color-white);
  background-color: var(--color-black);
  border-radius: 50%;

  & > .closeIconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}
