.component,
.componentCanonical {
  pointer-events: none;
  user-select: none;
}

.floatingOverlay {
  position: relative;
  background: transparent;
  transition: background var(--duration-300) ease;
  overflow: hidden !important;

  &.overlayActive {
    pointer-events: auto;
    background-color: var(--color-black--60);
  }

  & > .drawerLayout {
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100dvh;

    @media (--viewport-md) {
      max-width: 450px;
    }
  }
}

.componentDrawerWrapper {
  pointer-events: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  color: var(--color-white);
  background-color: var(--color-white);
  font-family: var(--font-family-base);
  touch-action: pan-y;

  & > .headerLayout {
    width: 100%;
  }

  & > .checkboxContainer {
    margin: auto 5%;
    width: 90%;
  }
}

.componentHeader {
  z-index: 0;
  position: relative;
  overflow: hidden;

  & > .imageContainer {
    z-index: -1;
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
  }

  & > .headerWrapper {
    width: 100%;
    height: 100%;
  }
}

.differentRegionsHeading {
  padding: var(--size-32) 5% var(--size-2);
  font-size: var(--font-size-24-28);
  font-weight: var(--font-weight-400);
  font-style: italic;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(var(--blur-26));

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--color-black--20);
  }

  & > .imageLayout {
    width: 100%;
    height: 100%;
  }
}

.headerWrapper {
  padding: var(--size-24);
}

.headerContentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-16);
  color: var(--color-white);

  & > .backButton {
    position: absolute;
    width: var(--size-22);
    height: var(--size-22);
    top: 0;
    right: 0;
  }

  & > .logoLayout {
    width: var(--size-72);
    height: var(--size-72);
    margin-top: var(--size-10);
  }

  & > .formLayout {
    width: 100%;
  }
}

.backButton {
  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.componentForm {
  position: relative;
  display: flex;
  align-items: center;
  color: var(--color-white);

  & > .inputContainer {
    width: 100%;
  }

  & > .iconLayout {
    position: absolute;
    width: var(--size-22);
    height: var(--size-22);
    left: var(--size-20);
  }

  & > .deletePromptButton {
    position: absolute;
    width: var(--size-18);
    height: var(--size-18);
    right: var(--size-20);
  }
}

.inputContainer {
  & > .inputLayout {
    width: 100%;
    height: var(--size-48);
  }
}

.componentSuggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-24);
}

.drawerContainer {
  display: flex;
  justify-content: center;
  padding: var(--size-24);

  & > .loaderLayout {
    width: var(--size-32);
    height: var(--size-32);
  }
}

.resultsContainer {
  color: var(--color-gray-900);
  padding-bottom: var(--size-64);
}

.componentHeading {
  color: var(--color-gray-900);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
  text-align: center;
}

.suggestionsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-8);
}

.componentSelectedSuggestion {
  padding: var(--size-24);

  & > .tagLayout {
    width: max-content;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--size-18) 0 var(--size-20);
}

.resultCountElement {
  font-size: var(--font-size-14);
  color: var(--color-black);
  text-transform: lowercase;
  font-style: italic;
}
