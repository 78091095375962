.component {
  z-index: 0;
  position: relative;

  & > .contentLayout {
    z-index: 1;
    position: static;
    margin-bottom: var(--size-72);

    @media (--viewport-lg) {
      width: 50%;
      margin: 0 auto var(--size-20-54);
    }
  }
}

.componentContent {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-14);

  &.white {
    color: var(--color-white);
  }

  &.black {
    color: var(--color-black);
  }

  & > .contentBottom {
    position: static;
  }
}

.componentBackgroundImage {
  pointer-events: none;

  & > .feedItemImageLayout {
    width: 100%;
    height: 100%;
  }
}

.contentBottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  & > .navigationButtonLayout {
    position: static;
    width: var(--size-32);
    height: var(--size-32);
  }
}

.componentNavigationButton {
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}
