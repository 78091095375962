.componentContent {
  --margin-sm: var(--size-8);
  --margin-md: var(--size-14);
  --margin-lg: var(--size-18);
  --margin-xl: var(--size-40);

  @media (--viewport-md) {
    --margin-sm: var(--size-12);
    --margin-md: var(--size-18);
    --margin-lg: var(--size-20);
    --margin-xl: var(--size-48);
  }

  --content-margin-sm: var(--margin-sm) 0 var(--margin-sm);
  --content-margin-md: var(--margin-md) 0 var(--margin-md);
  --content-margin-lg: var(--margin-lg) 0 var(--margin-lg);
  --content-margin-xl: var(--margin-xl) 0 var(--margin-xl);

  display: flex;
  flex-direction: column;

  &.snackable,
  &.default {
    & > .headingLayout {
      margin: var(--content-margin-sm);
      margin-bottom: 0;
    }

    & > .textLayout,
    & > .paragraphsLayout {
      margin: var(--content-margin-sm);
    }

    & > .ctaLayout {
      align-self: center;
      margin-top: var(--size-12);
      margin-bottom: var(--size-12);
    }

    & > .kaderLayout {
      margin: var(--content-margin-md);
    }

    & > .nameLayout {
      margin-top: var(--size-24);
    }
  }

  &.snackable {
    & > .ctaLayout {
      align-self: flex-start;
    }
  }

  &.page {
    & > .headingLayout {
      margin: var(--content-margin-sm);
      margin-bottom: 0;
    }

    & > .textLayout,
    & > .paragraphsLayout {
      margin: var(--content-margin-sm);
    }

    & > .introLayout,
    & > .ctaLayout {
      margin: var(--content-margin-md);
    }

    & > .zoomableLayout {
      margin: var(--content-margin-lg);
    }

    & > .imageSliderLayout,
    & > .imageLayout,
    & > .quoteLayout,
    & > .quoteWithImageLayout,
    & > .videoLayout,
    & > .audioLayout,
    & > .conclusionLayout,
    & > .kaderLayout {
      margin: var(--content-margin-xl);
    }
  }

  & > .textLayout,
  & > .paragraphsLayout {
    &:has(+ .headingLayout) {
      margin-bottom: var(--margin-xl);
    }
  }

  &.conclusionBase {
    font-family: var(--font-family-base);
  }

  .componentText {
    &.default,
    &.snackable {
      padding: var(--size-8) 0;
      font-family: var(--font-family-base);
      font-size: var(--font-size-16);
      color: var(--color-gray-400);
    }

    &.page {
      font-family: var(--font-family-alt);
      line-height: var(--line-height-text);
    }
  }

  .componentName {
    font-weight: var(--font-weight-600);
  }

  .componentPosition {
    &.page {
      font-family: var(--font-family-alt);
    }
  }

  .componentSubtitle {
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-18-22);
    line-height: var(--line-height-subheading);
  }

  .componentParagraphs {
    &.default,
    &.snackable {
      padding: var(--size-8) 0;
      font-family: var(--font-family-base);
      font-size: var(--font-size-16);
      color: var(--color-gray-400);
    }

    &.page {
      font-family: var(--font-family-alt);
      line-height: var(--line-height-text);
    }

    &.conclusion {
      font-weight: var(--font-weight-400);
      color: var(--color-black);
    }
  }

  .componentParagraphLink {
    text-decoration: underline;
  }

  .componentHeading {
    &.default,
    &.page {
      font-family: var(--font-family-base);
      font-size: var(--font-size-24-28);
      font-weight: var(--font-weight-600);
      line-height: var(--line-height-heading);
    }

    &.conclusion {
      font-family: var(--font-family-condensed-heading);
      font-size: var(--font-size-28-32);
      font-weight: var(--font-weight-600);
      text-transform: uppercase;
    }
  }

  .componentIntro {
    &.page {
      font-family: var(--font-family-base);
      font-size: var(--font-size-18-22);
      font-weight: var(--font-weight-600);
      line-height: var(--line-height-text);
    }

    &.conclusion {
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-14-20);
      color: var(--color-gray-400);
      padding-bottom: var(--size-8);
    }
  }

  .componentImage {
    display: flex;
    justify-items: flex-end;
    position: relative;
    z-index: 0;

    & > .captionContainer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .componentQuoteWithImage {
    position: relative;
    z-index: 0;

    & > .captionContainer {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .captionContainer {
    padding: var(--size-24);

    @media (--viewport-md) {
      padding: var(--size-40);
    }
  }

  .componentList {
    list-style-type: unset;
    padding-left: var(--size-24);
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16-20);
  }

  .componentConclusion {
    padding-top: var(--size-32);
    border-top: solid 1px var(--color-gray-900);
  }

  .componentKader {
    background-color: var(--color-cyan-300);
    padding: var(--size-24) var(--size-32);
  }

  .componentGedicht {
    &.page {
      padding: var(--size-20) var(--size-48);
      text-align: center;

      & > .poemLineLayout:not(:last-child) {
        margin-bottom: var(--size-20);
      }
    }
  }
}
