.component {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .menuContainer {
    width: fit-content;
    margin: 0 auto;
  }
}

.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--size-24);
  color: var(--color-white);
  background-color: var(--color-black--30);
  backdrop-filter: blur(var(--blur-26));
  border: 1px solid var(--color-white--20);
  transition: transform var(--duration-100) ease-in-out;
  overflow: hidden;

  & > * {
    height: var(--size-32);
  }

  &.isOpen {
    @media not (--viewport-md) {
      transform: translateY(2px);
    }

    & > * {
      height: var(--size-64);
    }
  }
}

.componentHamburger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-8) var(--size-16);
  border-radius: var(--radius-15);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
  font-style: italic;
  transition: var(--duration-600) ease;
  overflow: hidden;
}

.hamburgerContent {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: var(--size-16);
  transition: var(--duration-600) ease;
  transition-property: grid-template-columns, padding, opacity;
  overflow: visible;

  &.isOpen {
    opacity: 0;
    pointer-events: none;
    grid-template-columns: 0fr;
    padding: 0;
  }

  & > * {
    min-width: 0;
    width: 100%;
    height: 100%;
  }
}

.componentHamburgerIcon {
  display: grid;
  grid-template-areas: icon;
  place-content: center;

  &::before,
  &::after {
    content: '';
    grid-area: icon;
    height: 2px;
    width: var(--size-18);
    background-color: var(--color-white);
    transition: transform var(--duration-600) ease;
  }

  &::before {
    transform: translateY(-3px);
  }

  &::after {
    transform: translateY(3px) scaleX(0.6);
  }

  &.isOpen {
    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
}

.componentList {
  z-index: 0;
  position: relative;
  display: grid;
  grid-template-columns: 0fr;
  padding: var(--size-6) 0;
  transition: var(--duration-600) ease;
  transition-property: grid-template-columns, padding, height;
  overflow: hidden;

  &.isOpen {
    pointer-events: auto;
    grid-template-columns: 1fr;
    padding-right: var(--size-6);
  }

  & > .list {
    width: 100%;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  gap: var(--size-2);
  font-size: var(--font-size-14);
  overflow: hidden;

  @media (--viewport-md) {
    gap: var(--size-6);
  }

  & > .listItem {
    height: 100%;
  }
}

.listItem {
  --index: 0;

  opacity: 0;
  position: relative;
  display: flex;
  user-select: none;
  place-items: center;
  padding: 0 var(--size-18);
  border-radius: var(--radius-15);
  font-size: var(--font-size-12-14);
  font-style: italic;
  font-weight: var(--font-weight-600);
  -webkit-font-smoothing: antialiased;
  transform: translateX(calc(-1 * var(--size-10)));
  transition: var(--duration-200) ease-in-out;
  transition-delay: calc(var(--index) * var(--duration-100));
  transition-property: opacity, transform, background-color;

  &.isOpen {
    opacity: 1;
    transform: translateX(0);
  }

  &.isActive {
    background-color: var(--color-white--20);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  & > .itemLayout {
    position: static;
    height: 100%;
  }
}

.componentItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }

  & > .buttonLayout {
    position: static;
  }
}

.componentLink,
.componentButton {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.componentHamburgerContent {
  display: grid;
  grid-template-areas: 'content';
  place-content: center;

  & > * {
    grid-area: content;
  }
}

.menuText {
  opacity: 0;
  line-height: 2;
  transition: var(--duration-300) ease;
  transition-property: opacity, transform;
  transform: translateY(-12px);

  &.isActive {
    opacity: 1;
    transform: translateY(0);
  }
}

.componentCounter {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  font-size: var(--size-12);
  user-select: none;
  transition: var(--duration-300) ease;
  transition-property: opacity, transform;
  transform: translateY(12px);

  &.isActive {
    opacity: 1;
    transform: translateY(0);
  }
}

.currentContainer {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;

  & > .counter {
    grid-row-start: 1;
    grid-column-start: 1;
  }
}

.componentCheckIcon {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--size-16);
  color: var(--color-green-900);
  background-color: var(--color-white);
  border-radius: var(--radius-15);
  transform: scale(0.5);
  transition: var(--duration-300) ease;
  transition-property: opacity, transform;

  &.isActive {
    opacity: 1;
    transform: scale(0.9);
  }

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);
  }
}
