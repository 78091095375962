.componentPhotoIconOverlay {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  font-size: var(--font-size-12);
  position: relative;
  color: var(--color-black);

  &.isClosed {
    touch-action: none;
    pointer-events: none;
  }

  & > .cameraButtonLayout {
    align-self: flex-start;
    height: var(--size-32);
    width: var(--size-32);
    margin-top: var(--size-8);
  }
}

.componentContent {
  font-size: var(--font-size-14);
  background-color: var(--color-white);
  padding: var(--size-14);
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-12);
}
