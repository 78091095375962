.component {
  display: inline-grid;
  justify-items: center;
  padding: var(--size-18);
  border-radius: var(--radius-25);
  background-color: var(--color-cyan-100);
  pointer-events: none;

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }

  & > .buttonLayout {
    pointer-events: auto;
  }
}

.componentPermission.isDisabled {
  color: var(--color-gray-500);
}

.label {
  display: flex;
  gap: var(--size-10);
  align-items: center;
}

.checkbox {
  appearance: checkbox;
}

.componentOption {
  line-height: 1;
  border-radius: var(--radius-12);
  font-size: var(--font-size-14-16);
  padding: var(--size-12) var(--size-24);
  transition: outline var(--duration-200) ease;
  background-color: var(--color-white);
  pointer-events: auto;
  outline-offset: 2px;
  outline: 3px solid transparent;

  &:has(:checked) {
    outline-color: var(--color-green-100);
  }
}

.options {
  display: flex;
  gap: var(--size-10);
  flex-direction: column;

  &:hover > *:not(:hover) {
    opacity: 0.75;
  }

  @media (--viewport-md) {
    flex-direction: row;
  }
}
