
.component {
  border-radius: var(--radius-15);
  color: var(--color-white);
  background-color: var(--color-black);
  padding: var(--size-14) var(--size-12);
  font-size: var(--font-size-12);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;

  & > .contentLayout {
    width: 80%;
  }

  & > .iconLayout {
    margin-right: 10px;
    min-width: 23px;
    min-height: 23px;
  }
}

.icon {
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
