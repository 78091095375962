.componentHome {
  --color-gray-900: var(--color-green-300);

  position: relative;
  padding: var(--size-20) 0 var(--size-80);
  background-color: var(--color-green-100);

  @media (--viewport-md) {
    padding: var(--size-40) 0 var(--size-64);
  }
}

.componentToc {
  --color-gray-900: var(--color-gray-500);

  position: relative;
  background-color: var(--color-cyan-300);
  padding: var(--size-20) 5% var(--size-80);
}

.componentBase {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-32);

  & > .linksContainer {
    width: 100%;
  }
}

.poweredByText {
  font-size: var(--font-size-24);
  color: var(--color-gray-900);
  font-style: italic;
  font-weight: var(--font-weight-400);
  text-align: center;
  opacity: 0.7;
}

.aboutRaboText {
  text-align: left;
  font-size: var(--font-size-20);
}

.deckIndexPoweredByCursive {
  font-weight: var(--font-weight-700);
}

.linksContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: var(--size-8);
  font-size: var(--font-size-16);
  font-style: italic;
  text-align: center;

  & > .linkLayout {
    width: max-content;
  }
}

.componentLink {
  &:hover {
    text-decoration: underline;
  }
}
