.componentBase {
  display: flex;
  align-items: center;
  gap: var(--size-6);
  padding-right: var(--size-18);
  border-radius: var(--radius-32);
  color: var(--color-gray-900);
  transition: background-color var(--duration-200) ease;
  overflow: hidden;

  & > .iconLayout {
    width: var(--size-10);
    height: var(--size-10);
  }
}

.label {
  padding: var(--size-12) var(--size-24);
  padding-right: var(--size-4);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
}

.componentGreen {
  background-color: var(--color-green-100--50);

  &:hover {
    background-color: var(--color-green-100);
  }
}

.componentCream {
  background-color: var(--color-cream-100--50);

  &:hover {
    background-color: var(--color-cream-100);
  }
}

.componentCyan {
  background-color: var(--color-cyan-100--50);

  &:hover {
    background-color: var(--color-cyan-100);
  }
}
