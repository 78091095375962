:root {
  --container-max-width-sm: 600px;
  --container-max-width-md: 768px;
  --container-max-width-lg: 1024px;
  --container-max-width-xl: 1500px;

  --padding-inline: 10vw;

  --container-padding-x: min(5vw, 2.5vw + 24px);
  --container-padding: 0 var(--container-padding-x);
}
