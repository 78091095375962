.componentSingleSnackable,
.componentMemberOffer,
.componentSnackables,
.componentBordered,
.componentBase {
  position: relative;
  display: grid;
  pointer-events: none;
  user-select: none;

  & > .introWrapper {
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;

    &.isNextArticleIntro {
      position: relative;
    }
  }
}

.componentDefault,
.componentDefaultBlack {
  --font-size-title: var(--font-size-34);
}

.componentDefault,
.componentBordered {
  color: var(--color-white);
}

.componentDefaultBlack {
  color: var(--color-black);
}

.componentGedicht {
  --font-size-title: var(--font-size-42-65);

  color: var(--color-white);
  text-align: left;

  @media (--viewport-md) {
    --font-size-title: var(--font-size-75-115);
  }
}

.componentBordered {
  & > .introWrapper {
    width: calc(100% - var(--size-20));
    margin-left: var(--size-20);

    @media (--viewport-lg) {
      width: 100%;
      margin: 0;
    }
  }
}

.introWrapper {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "..."
    "content";

  & > .headingLayout,
  & > .imageDesciptionLayout {
    grid-area: content;
    align-self: flex-end;
    bottom: 0;
  }

  & > .headingLayout {
    @media not (--viewport-md) {
      width: calc(100% - (5vw + var(--size-24) + var(--size-40))); /*  room for share button */
    }
  }

  & > .introMemberOfferLayout {
    width: 100%;
  }
}

.componentHeadingBase,
.componentImageDescription {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: var(--size-100);
}

.componentHeadingBase {
  opacity: 1;
  transform: translateY(0);
}

.headerLayoutWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-12);

  & > .arrowIconLayout {
    position: absolute;
    height: var(--size-18);
    width: var(--size-18);
    right: calc(-1 * (5vw + var(--size-24) + var(--size-40)));
    bottom: 0;
    margin-left: auto;
  }
}

.componentImageDescription {
  opacity: 0;
  transform: translateY(100%);
}

.descriptionWrapper {
  padding-left: var(--size-12);
  border-left: var(--size-4) solid var(--color-white);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--size-8);
  font-family: var(--font-family-base);
  font-size: var(--font-size-16);
  line-height: var(--line-height-text);
}

.componentImageDescriptionItem {
  & > .descriptionLabel {
    margin-right: var(--size-4);
  }
}

.descriptionLabel {
  font-weight: var(--font-weight-600);
  text-transform: capitalize;
}

.descriptionValue {
  font-weight: var(--font-weight-300);
}
