.componentBase {
  padding: var(--size-24);
  background-color: var(--color-white--20);
  backdrop-filter: var(--blur-26);
  border-radius: var(--radius-25);
  outline: 1px solid var(--color-white--20);
  text-align: center;
  -moz-appearance: textfield;

  &::placeholder {
    color: var(--color-white--50);
  }

  &:focus,
  &.hasValue {
    text-align: left;

    &.hasIcon {
      padding-left: var(--size-54);
    }

    &::placeholder {
      color: transparent;
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
