.component {
  & > .splashLayout {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    height: 100svh;
  }

  & > .slidersContainer {
    width: 100%;
    overflow: hidden;
  }

  & > .footerLayout {
    margin-top: var(--size-80);
  }
}

.container {
  & > .selectorContainer {
    width: 100%;
    height: 100svh;
    overflow: hidden;
  }
}

.selectorContainer {
  position: relative;
  z-index: 0;

  & > .searchButtonLayout {
    z-index: 1;
    position: fixed;
    right: var(--size-18);
    top: var(--size-18);
  }
}

.componentSearchButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--size-6);
  font-style: italic;
  font-size: var(--font-size-14);
  color: var(--color-white);
  padding: var(--size-4);
}

.slidersContainer {
  position: relative;
  padding-top: var(--size-80);
  color: var(--color-white);
}

.componentBottomGradient {
  pointer-events: none;
  position: relative;

  & > .gradient {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
  }
}

.gradient {
  background: linear-gradient(180deg, transparent 0%, var(--color-green-300) 45.31%);
  transform-origin: bottom;
}
