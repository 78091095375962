.component {
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}

.slider {
  padding: 0 var(--size-24) var(--size-40) 0;
  overflow: visible !important;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

.componentIssueCardBase {
  z-index: 0;
  position: relative;
  display: grid;
  place-items: center;
  grid-template-areas: 'content';
  aspect-ratio: 1 / 1;
  border-radius: var(--radius-20);
  box-shadow: var(--shadow-md);

  & > * {
    grid-area: content;
  }

  & > .buttonLayout {
    z-index: 1;
    width: max-content;
    position: static;
    place-self: start;
    align-self: end;
    margin: var(--size-16);
  }
}

.imageAndLogo {
  position: relative;

  & > .logo {
    position: absolute;
    top: var(--size-16);
    left: var(--size-16);
  }
}
