.component {
  --border-color: var(--color-green-100);
  --border-offset: 0;

  &:focus-within {
    --border-color: var(--color-green-300);
    --border-offset: 2px;
  }

  display: flex;
  gap: var(--size-12);
  align-items: center;
  position: relative;
  color: var(--color-black);
  padding: var(--size-12);
  position: relative;

  & > .checkboxLabel {
    position: static;
  }

  & > .inputCheckbox {
    position: absolute;
    inset: 0;
  }

  & > .checkbox {
    order: -1;
  }
}

.checkboxLabel {
  font-size: var(--font-size-14);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

.inputCheckbox {
  cursor: pointer;
  opacity: 0;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  outline-offset: var(--border-offset);
  outline: 2px solid var(--border-color);
  transition: outline var(--duration-100) ease;
  background-color: var(--color-green-100);
  border-radius: var(--radius-4);
  padding: var(--size-4);
  aspect-ratio: 1 / 1;
  color: transparent;
  cursor: pointer;

  &.isChecked {
    color: var(--color-green-300);
  }

  & > * {
    width: var(--size-14);
    height: var(--size-14);
  }
}
