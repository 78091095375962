.componentRegionContent {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .regionCover {
    height: 100%;
    width: 100%;
    justify-self: flex-start;
    margin: 0 auto;
  }
}

.shareModalBase {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  gap: var(--size-24);
  padding: var(--size-48) var(--size-30) var(--size-20);
  background: var(--gradient-cyan);
  border-radius: var(--radius-20);

  @media (--viewport-md) {
    gap: var(--size-48);
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 2fr;
  }

  & > .regionLayout {
    height: 100%;
  }
}

.componentRegion {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .regionShareContentLayout {
    width: 100%;
    height: 100%;

    @media (--viewport-md) {
      width: 80%;
      height: 80%;
    }
  }
}

.componentArticle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--size-10);

  @media (--viewport-lg) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & > .imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65%;

    @media (--viewport-lg) {
      position: relative;
      height: 100%;
      width: 100%;
    }
  }
}

.componentShareContainer {
  & > .shareOptions {
    margin-top: var(--size-10);
  }
}

.shareOptions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--size-16);

  & > .shareIcon {
    min-width: 56px;
    min-height: 56px;
  }
}

.shareIcon {
  display: grid;
  place-items: center center;
  border-radius: 50%;

  & > .shareIconLayout {
    margin-top: -2px;
  }
}

.shareHeading {
  font-size: var(--font-size-42);
  line-height: var(--line-height-share-modal);
  font-weight: var(--font-weight-600);
  white-space: pre-line;

  @media (--viewport-lg) {
    font-size: var(--font-size-55);
  }
}

.regionCover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-xs);

  & > .imageLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .regionCoverLogo {
    z-index: 2;
  }
}

.regionCoverLogo {
  position: relative;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: italic;
}

.regionCoverIssue {
  transform: translateY(calc(-1 * var(--size-16)));
  font-size: var(--font-size-14);
}

.copyToClipboard {
  position: relative;

  & > .copyToClipboardModalLayout {
    position: absolute;
    top: -50px;
    left: -200px;
    width: 220px;
  }
}

.checkmarkIconColor {
  color: var(--color-green-900);
}

.imageWrapper {
  & > .imageGrid {
    width: 100%;
    height: 100%;
  }
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: var(--size-10);

  & > * {
    box-shadow: var(--shadow-md);
    width: 100%;
    height: 100%;
  }

  & > :first-child {
    grid-area: 1 / 1 / 3 / 2;
  }

  & > :nth-child(2) {
    grid-area: 4 / 1 / 7 / 3;
    margin-top: -40px;
  }

  & > :nth-child(3) {
    grid-area: 2 / 2 / 4 / 4;
    margin-top: -40px;
  }

  & > :nth-child(4) {
    grid-area: 2 / 4 / 7 / 7;
  }
}
