.component {
  z-index: 0;
  position: relative;
  height: 100vh !important;
  height: 100dvh !important;
  background-color: var(--color-green-300);
  touch-action: none;
  overflow: hidden;

  & > .deckContainer {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    top: 0;
    left: 0;
  }

  & > .tocLayout,
  & > .searchLayout {
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    top: 0;
  }

  & > .menuLayout {
    z-index: 3;
    position: fixed;
    width: fit-content;
    left: 0;
    right: 0;
    bottom: var(--size-24);
    margin: 0 auto;
  }

  & > .gestureInstructionLayout {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.deckContainer {
  overflow: hidden;

  & > .deckLayout {
    width: 100%;
    height: 100%;
  }
}

.componentDeck {
  z-index: 0;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  user-select: none;

  & > .deckLogoLayout {
    z-index: 1;
  }

  & > .cardLayout {
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 90vh;
    height: 90dvh;
    bottom: 0;

    @media (--viewport-lg) {
      width: 80dvw;
      max-width: 1600px;
      height: 80dvh;
      bottom: 5dvh;
      left: 50%;
    }
  }
}

.componentUniversal {
  & > .loaderLayout {
    height: 100vh !important;
    height: 100dvh !important;
  }
}

.componentCard {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--size-32);
  overflow: hidden;
  position: relative;

  & > .cardContentLayout {
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    pointer-events: none;
    background: var(--gradient-black);
    opacity: var(--overlay-opacity);
    position: absolute;
    inset: 0;
  }
}
