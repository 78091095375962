.component {
  pointer-events: none;
  user-select: none;
}

.floatingOverlay {
  background: transparent;
  transition: background var(--duration-300) ease;
  overflow: hidden;

  &.overlayActive {
    pointer-events: auto;
    background-color: var(--color-black--60);
  }

  & > .deckIndexLayout {
    width: 100%;
    height: 100vh;
    height: 100dvh;

    @media (--viewport-md) {
      max-width: 450px;
    }
  }
}

.componentDrawer {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: var(--color-white);
  font-family: var(--font-family-base);
  touch-action: pan-y;

  & > .deckIndexHeader {
    height: 250px;
  }
}

.issueSliderContainer {
  padding: var(--size-72) 5% var(--size-48);
}

.deckIndexHeader {
  z-index: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--size-24);

  & > .backButton {
    z-index: 2;
    position: absolute;
    width: var(--size-22);
    height: var(--size-22);
    top: var(--size-20);
    left: var(--size-22);
  }

  & > .deckIndexHeaderImageLayout {
    z-index: 0;
    height: 100%;
    position: absolute;
    object-position: 50% 30% !important;
    top: 0;
    left: 0;
  }

  & > .shareButtonLayout {
    z-index: 2;
    position: absolute;
    top: var(--size-14);
    right: var(--size-14);
    height: var(--size-40);
    width: var(--size-40);
  }

  & > .regionIndexTitle {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  & > .deckIndexAmount {
    z-index: 1;
    position: relative;
  }
}

.backButton {
  color: var(--color-white);

  & > .iconLayout {
    width: 100%;
    height: 100%;
  }
}

.regionIndexTitle {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: var(--size-20);
  color: var(--color-white);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-600);
  font-style: italic;
}

.deckIndexAmount {
  border: 1px solid var(--color-white--20);
  color: var(--color-white);
  border-radius: var(--size-24);
  font-size: var(--font-size-14);
  padding: var(--size-8) var(--size-16);
  display: flex;
  background-color: var(--color-black--10);
  backdrop-filter: blur(var(--blur-10));
}

.componentBecomeMember {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  padding: var(--size-48) 5%;
  background-color: var(--color-green-100);

  & > .description {
    margin-bottom: var(--size-12);
  }

  & > .buttonLayout {
    width: max-content;
  }
}

.description {
  font-size: var(--font-size-20);
}
